export const DeliveryPrices = [
  {
    value: "SPINTEX",
    label: "SPINTEX",
    price: 20,
  },
  {
    value: "PALACE MALL (SPINTEX)",
    label: "PALACE MALL (SPINTEX)",
    price: 25,
  },
  {
    value: "ACCRA MALL",
    label: "ACCRA MALL",
    price: 27,
  },
  {
    value: "ACCRA METROPOLITAN",
    label: "ACCRA METROPOLITAN",
    price: 43,
  },
  {
    value: "ACTION CHAPEL",
    label: "ACTION CHAPEL",
    price: 25,
  },
  {
    value: "ACHIMOTA",
    label: "ACHIMOTA",
    price: 50,
  },
  {
    value: "ADABRAKA",
    label: "ADABRAKA",
    price: 46,
  },
  {
    value: "ADENTA",
    label: "ADENTA",
    price: 50,
  },
  {
    value: "ADENTA FRAFRAHA",
    label: "ADENTA FRAFRAHA",
    price: 55,
  },
  {
    value: "ABLEKUMA",
    label: "ABLEKUMA",
    price: 65,
  },
  {
    value: "ABELEMKPE",
    label: "ABELEMKPE",
    price: 48,
  },
  {
    value: "ABOFU",
    label: "ABOFU",
    price: 40,
  },
  {
    value: "ABOSSO OKAI",
    label: "ABOSSO OKAI",
    price: 50,
  },
  {
    value: "ACCRA NEW TOWN",
    label: "ACCRA NEW TOWN",
    price: 45,
  },
  {
    value: "AGBOGBLOSHIE",
    label: "AGBOGBLOSHIE",
    price: 45,
  },
  {
    value: "OGBODJO",
    label: "OGBODJO",
    price: 36,
  },
  {
    value: "AIRPORT RESIDENTIAL",
    label: "AIRPORT RESIDENTIAL",
    price: 31,
  },
  {
    value: "AKWETEMAN",
    label: "AKWETEMAN",
    price: 42,
  },
  {
    value: "ALAJO",
    label: "ALAJO",
    price: 50,
  },
  {
    value: "ADJIRINGANOR",
    label: "ADJIRINGANOR",
    price: 40,
  },
  {
    value: "AGBOGBA",
    label: "AGBOGBA",
    price: 45,
  },
  {
    value: "ABOKOBI",
    label: "ABOKOBI",
    price: 60,
  },
  {
    value: "AMASAMAN",
    label: "AMASAMAN",
    price: 70,
  },
  {
    value: "APENKWA",
    label: "APENKWA",
    price: 45,
  },
  {
    value: "ANYAA",
    label: "ANYAA",
    price: 62,
  },
  {
    value: "ASHAIMAN",
    label: "ASHAIMAN",
    price: 40,
  },
  {
    value: "ASHALEY BOTWE",
    label: "ASHALEY BOTWE",
    price: 45,
  },
  {
    value: "ASHONMAN ESTATE",
    label: "ASHONMAN ESTATE",
    price: 60,
  },
  {
    value: "ASYLUM DOWN",
    label: "ASYLUM DOWN",
    price: 45,
  },
  {
    value: "AVENOR AREA",
    label: "AVENOR AREA",
    price: 50,
  },
  {
    value: "AWOSHIE",
    label: "AWOSHIE",
    price: 55,
  },
  {
    value: "AWUDOME",
    label: "AWUDOME",
    price: 50,
  },
  {
    value: "ATOMIC",
    label: "ATOMIC",
    price: 52,
  },
  {
    value: "BANANA INN",
    label: "BANANA INN",
    price: 55,
  },
  {
    value: "BUBUASHIE",
    label: "BUBUASHIE",
    price: 52,
  },
  {
    value: "BURMA CAMP",
    label: "BURMA CAMP",
    price: 40,
  },
  {
    value: "COASTAL JUNCTION",
    label: "COASTAL JUNCTION",
    price: 15,
  },
  {
    value: "CENTRAL BUSINESS DISTRICT",
    label: "CENTRAL BUSINESS DISTRICT",
    price: 45,
  },
  {
    value: "CHORKOR",
    label: "CHORKOR",
    price: 50,
  },
  {
    value: "CIRCLE",
    label: "CIRCLE",
    price: 45,
  },
  {
    value: "McCarthy Hill",
    label: "McCarthy Hill",
    price: 60,
  },
  {
    value: "DANSOMAN",
    label: "DANSOMAN",
    price: 60,
  },
  {
    value: "DARKUMAN",
    label: "DARKUMAN",
    price: 50,
  },
  {
    value: "DOME",
    label: "DOME",
    price: 50,
  },
  {
    value: "DZORWULO",
    label: "DZORWULO",
    price: 40,
  },
  {
    value: "DODOWA",
    label: "DODOWA",
    price: 90,
  },
  {
    value: "EAST LEGON",
    label: "EAST LEGON",
    price: 30,
  },
  {
    value: "EAST LEGON HILLS",
    label: "EAST LEGON HILLS",
    price: 50,
  },
  {
    value: "TEMA",
    label: "TEMA",
    price: 30,
  },
  {
    value: "TEMA COMM 25",
    label: "TEMA COMM 25",
    price: 45,
  },
  {
    value: "DAWHENYA",
    label: "DAWHENYA",
    price: 58,
  },
  {
    value: "KPONE",
    label: "KPONE",
    price: 55,
  },
  {
    value: "PRAMPRAM",
    label: "PRAMPRAM",
    price: 75,
  },
  {
    value: "UNIVERSITY OF GHANA",
    label: "UNIVERSITY OF GHANA",
    price: 45,
  },
  {
    value: "BETHLEHEM, GOLF CITY",
    label: "BETHLEHEM, GOLF CITY",
    price: 40,
  },
  {
    value: "MICHELE CAMP",
    label: "MICHELE CAMP",
    price: 50,
  },
  {
    value: "AFIENYA",
    label: "AFIENYA",
    price: 65,
  },
  {
    value: "MANET",
    label: "MANET",
    price: 25,
  },
  {
    value: "COCA COLA",
    label: "COCA COLA",
    price: 20,
  },
  {
    value: "LASHIBI",
    label: "LASHIBI",
    price: 10,
  },
  {
    value: "KLAGON",
    label: "KLAGON",
    price: 20,
  },
  {
    value: "18 ROAD",
    label: "18 ROAD",
    price: 15,
  },
  {
    value: "CAMBODIA",
    label: "CAMBODIA",
    price: 20,
  },
  {
    value: "LAPAZ",
    label: "LAPAZ",
    price: 54,
  },
  {
    value: "CANTOMENTS",
    label: "CANTOMENTS",
    price: 45,
  },
  {
    value: "TESHIE",
    label: "TESHIE",
    price: 20,
  },
  {
    value: "NUNGUA",
    label: "NUNGUA",
    price: 20,
  },
  {
    value: "TSE ADDO",
    label: "TSE ADDO",
    price: 40,
  },
  {
    value: "LA",
    label: "LA",
    price: 40,
  },
  {
    value: "LABADI",
    label: "LABADI",
    price: 40,
  },
  {
    value: "LABONE",
    label: "LABONE",
    price: 40,
  },
  {
    value: "BORTEYMAN",
    label: "BORTEYMAN",
    price: 50,
  },
  {
    value: "NIMA",
    label: "NIMA",
    price: 45,
  },
  {
    value: "KANDA",
    label: "KANDA",
    price: 45,
  },
  {
    value: "GBAWE",
    label: "GBAWE",
    price: 75,
  },
  {
    value: "MATAHEKO",
    label: "MATAHEKO",
    price: 60,
  },
  {
    value: "KOTOBABI",
    label: "KOTOBABI",
    price: 50,
  },
  {
    value: "NORTH KANESHIE",
    label: "NORTH KANESHIE",
    price: 55,
  },
  {
    value: "OKAISHIE",
    label: "OKAISHIE",
    price: 45,
  },
  {
    value: "OSU",
    label: "OSU",
    price: 40,
  },
  {
    value: "OYIBI",
    label: "OYIBI",
    price: 70,
  },
  {
    value: "OYARIFA",
    label: "OYARIFA",
    price: 60,
  },
  {
    value: "PANTANG",
    label: "PANTANG",
    price: 60,
  },
  {
    value: "PIG FARM",
    label: "PIG FARM",
    price: 45,
  },
  {
    value: "ROMAN RIDGE",
    label: "ROMAN RIDGE",
    price: 45,
  },
  {
    value: "SANTA MARIA",
    label: "SANTA MARIA",
    price: 65,
  },
  {
    value: "TAIFA- BURKINA",
    label: "TAIFA- BURKINA",
    price: 68,
  },
  {
    value: "TAIFA",
    label: "TAIFA",
    price: 65,
  },
  {
    value: "TANTRA HILLS",
    label: "TANTRA HILLS",
    price: 60,
  },
  {
    value: "KASOA",
    label: "KASOA",
    price: 90,
  },
  {
    value: "TESANO",
    label: "TESANO",
    price: 50,
  },
  {
    value: "SUKURA",
    label: "SUKURA",
    price: 55,
  },
  {
    value: "TUDU",
    label: "TUDU",
    price: 45,
  },
  {
    value: "WEIJA",
    label: "WEIJA",
    price: 75,
  },
  {
    value: "WEST LEGON",
    label: "WEST LEGON",
    price: 50,
  },
  {
    value: "LASHIBI",
    label: "LASHIBI",
    price: 1,
  },
  {
    value: "TABORA",
    label: "TABORA",
    price: 65,
  },
  {
    value: "ZOTI AREA",
    label: "ZOTI AREA",
    price: 55,
  },
  {
    value: "UPSA",
    label: "UPSA",
    price: 40,
  },
  {
    value: "SOUTH LA",
    label: "SOUTH LA",
    price: 35,
  },
  {
    value: "SOWUTUOM",
    label: "SOWUTUOM",
    price: 70,
  },
  {
    value: "SEMPE NEW TOWN",
    label: "SEMPE NEW TOWN",
    price: 65,
  },
  {
    value: "OFANKOR",
    label: "OFANKOR",
    price: 65,
  },
  {
    value: "HAATSO",
    label: "HAATSO",
    price: 45,
  },
  {
    value: "MADINA",
    label: "MADINA",
    price: 40,
  },
  {
    value: "KORLE-BU",
    label: "KORLE-BU",
    price: 50,
  },
  {
    value: "KORLE GONNO",
    label: "KORLE GONNO",
    price: 50,
  },
  {
    value: "MAMOBI",
    label: "MAMOBI",
    price: 50,
  },
  {
    value: "MAMPROBI",
    label: "MAMPROBI",
    price: 50,
  },
  {
    value: "NEW MAMPROBI",
    label: "NEW MAMPROBI",
    price: 55,
  },
  {
    value: "NORTH INDUSTRIAL AREA",
    label: "NORTH INDUSTRIAL AREA",
    price: 55,
  },
  {
    value: "OLD ASHONMANG",
    label: "OLD ASHONMANG",
    price: 55,
  },
  {
    value: "NII BOI TOWN",
    label: "NII BOI TOWN",
    price: 50,
  },
  {
    value: "LARTERBIOKORSHIE",
    label: "LARTERBIOKORSHIE",
    price: 55,
  },
  {
    value: "LEDZOKUKU-KROWOR",
    label: "LEDZOKUKU-KROWOR",
    price: 25,
  },
  {
    value: "KLAGON",
    label: "KLAGON",
    price: 25,
  },
  {
    value: "ADJEI KOJO",
    label: "ADJEI KOJO",
    price: 30,
  },
  {
    value: "GOLF CITY",
    label: "GOLF CITY",
    price: 40,
  },
  {
    value: "AFARIWA",
    label: "AFARIWA",
    price: 45,
  },
  {
    value: "BASKET JUNCTION",
    label: "BASKET JUNCTION",
    price: 15,
  },
  {
    value: "KFC",
    label: "KFC",
    price: 10,
  },
  {
    value: "SAKUMONO",
    label: "SAKUMONO",
    price: 10,
  },
  {
    value: "ADE GONNO",
    label: "ADE GONNO",
    price: 15,
  },
  {
    value: "DEVTRACCO",
    label: "DEVTRACCO",
    price: 20,
  },
  {
    value: "COMM 19",
    label: "COMM 19",
    price: 15,
  },
];
