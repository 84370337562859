export const menuItems = [
  {
    id: 1,
    name: "3 Pieces of Spring Roll(crunchy stuffed with meat chunks and veggies)",
    price: 20,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg%20(10).png?alt=media&token=a42fff7d-d995-45f2-92a1-cd02791a3527",
    category: "starters",
  },
  {
    id: 2,
    name: "3 Pieces of Samose(addictively crunch stuffed with minced meat or chicken )",
    price: 20,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/efa2070b-452b-4d52-868a-49a2b440dc5b.JPG?alt=media&token=ea0fc938-9c78-4138-8f71-7fa601f9d971",
    category: "starters",
  },
  // {
  //   id: 3,
  //   name: "KnK salt &Pepper Calamari(Fresh local squid rings, crispy with mayo dip garnished with parsley)",
  //   price: 80,
  //   img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_x0icikx0icikx0ic.jpeg?alt=media&token=ab8b4c1e-dd86-4e8c-b055-24cb568e56d1",
  //   category: "starters",
  // },
  {
    id: 4,
    name: "Prawn crackers( a deep fried snack made from starch and pawns)",
    price: 20,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_gqbw29gqbw29gqbw.jpeg?alt=media&token=3ff5dbb3-6816-4a9c-ad01-068d59b5d9ab",
    category: "starters",
  },
  {
    id: 5,
    name: "Chicken Avo Salad",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Avocado-Chicken-Salad-4.jpg?alt=media&token=3b19205b-9210-4d27-9a1d-a73a70910011",
    category: "salads",
  },
  {
    id: 6,
    name: "Gh style salad",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.31_8c58af13.jpg?alt=media&token=09975909-3114-4f33-b877-69252dddc90e",
    category: "salads",
  },
  {
    id: 7,
    name: "Bread(white bread)Club sandwich",
    price: 70,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/6a4ea771-1f21-4138-9863-81fd132aa769.JPG?alt=media&token=8034799f-4a0e-4fde-b6cc-eec6f7268010",
    category: "sandwiches",
  },
  {
    id: 8,
    name: "Tuna sandwich",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_krt87fkrt87fkrt8.jpeg?alt=media&token=33eb31f0-5fd7-49bb-ba0e-92c015b1805d",
    category: "sandwiches",
  },
  {
    id: 9,
    name: "Egg Avo Sandwich",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.28_6826e3af.jpg?alt=media&token=9ad0de47-58b5-4dd1-aed6-3edd277fc5a4",
    category: "sandwiches",
  },
  {
    id: 10,
    name: "Chicken Avo sandwich",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.31_809f47a3.jpg?alt=media&token=1c0115a6-3e84-46cf-aa79-be05c4ab8939",
    category: "sandwiches",
  },
  {
    id: 11,
    name: "Ham &Cheese Sandwich",
    price: 70,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_qd07fpqd07fpqd07.jpeg?alt=media&token=308f120f-ff10-4291-b5b2-4cc82c107512",
    category: "sandwiches",
  },
  {
    id: 12,
    name: "Bread roll sandwich",
    price: 50,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.31_51f9db00.jpg?alt=media&token=d5a5d539-958e-4c7c-adc8-a9978c40f237",
    category: "sandwiches",
  },
  {
    id: 13,
    name: "Chicken Shawarma",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_dr9d6mdr9d6mdr9d.jpeg?alt=media&token=63039d5a-cc5d-47ab-8bda-42b140f191ef",
    category: "shawarma",
  },
  {
    id: 14,
    name: "Beef Shawarma",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg%20(1).png?alt=media&token=774602f6-f486-4936-9707-8045794151fe",
    category: "shawarma",
  },
  {
    id: 15,
    name: "Assorted Shawarma",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/f32c1c4f-0720-4afd-80c7-6d6541a1c853.JPG?alt=media&token=6b12fbfc-5067-46d5-b006-f373244b7342",
    category: "shawarma",
  },
  {
    id: 16,
    name: "Egg Shawarma",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg%20(2).png?alt=media&token=4d52a293-746a-4151-8f7d-94df73759e35",
    category: "shawarma",
  },
  {
    id: 17,
    name: "Breakfast wraps",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_t8a51nt8a51nt8a5.jpeg?alt=media&token=96b561c9-3144-4500-9d8d-4abb36a7460a",
    category: "wraps",
  },
  {
    id: 18,
    name: "Chicken Avo Wraps",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.28_064efe89.jpg?alt=media&token=86a20c2a-6ed3-4686-904d-a4c69c4f1e46",
    category: "wraps",
  },
  // {
  //   id: 19,
  //   name: "Egg Avo Wraps",
  //   price: 45,
  //   img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_x0icikx0icikx0ic.jpeg?alt=media&token=ab8b4c1e-dd86-4e8c-b055-24cb568e56d1",
  //   category: "wraps",
  // },
  {
    id: 20,
    name: "Tuna wrap",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_u1pyj3u1pyj3u1py.jpeg?alt=media&token=ed7a488d-5d48-4f85-8623-b01f8c38eed6",
    category: "wraps",
  },
  {
    id: 21,
    name: "Chicken fajita(Avocado,lettuce,mozzarella) Wraps",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.31_290628fc.jpg?alt=media&token=3a84d5eb-d226-4d5e-9482-fcbd52466aed",
    category: "wraps",
  },
  {
    id: 22,
    name: "Chicken/Beef stir fry spaghetti",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_h4n90bh4n90bh4n9.jpeg?alt=media&token=8864b749-2648-428a-82fe-e895ddfc64da",
    category: "noodles",
  },
  {
    id: 23,
    name: "Assorted stir fry  spaghetti(chicken,beef,sausage & eggs )",
    price: 65,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.29_a3b8eb87.jpg?alt=media&token=9eece4ed-1d94-46fd-bd6a-5c1f6ac99f37",
    category: "noodles",
  },
  {
    id: 24,
    name: "Knk Assorted spaghetti(Prawns,squid etc)",
    price: 80,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/0ccc5602-59ed-4f7d-9830-91e6a54e4f1e.JPG?alt=media&token=7aa9fc74-cf3e-424c-8024-65a5a7e9fc58",
    category: "noodles",
  },
  {
    id: 25,
    name: "Fried rice-chicken",
    price: 65,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/OIP-1.jpeg?alt=media&token=052f87f0-3b39-4cc1-a2e7-6fcbafa30d2c",
    category: "rice",
  },
  {
    id: 26,
    name: "Jollof rice -chicken",
    price: 65,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/84b1cad9-7359-4583-97d6-e70369cb9f72.JPG?alt=media&token=293f76be-d306-434e-b94c-7fbb8c5be7be",
    category: "rice",
  },
  {
    id: 27,
    name: "Assorted fried rice",
    price: 75,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/0e766954-2c88-40ad-9e10-5ce59dc93201.JPG?alt=media&token=7e372125-4dba-48d7-8302-79dba9c617d5",
    category: "rice",
  },
  {
    id: 28,
    name: "Assorted Jollof",
    price: 75,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.32_fe6fb8d1.jpg?alt=media&token=ab0635c5-5a6a-4df9-bfbe-33ed3598d8d2",
    category: "rice",
  },
  {
    id: 29,
    name: "Angwamo",
    price: 55,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.29_d9ac2fb3.jpg?alt=media&token=506037c5-5eb1-40cf-a1c2-13b644f1bcfe",
    category: "rice",
  },
  {
    id: 31,
    name: "English breakfast(Eggs,sausage,bacon,baked beans,grilled tomatoes,\n skillet potatoes , toast and Tea",
    price: 150,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/1279a334-be11-47c1-a806-4bce66db337a.JPG?alt=media&token=0b961543-c992-4b8f-84ea-425572fecdb0",
    category: "brunch",
  },
  {
    id: 32,
    name: "Plain creamy oats(Served with cream milkBanana,honey,egg&bread)",
    price: 50,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.32_9c5451b0.jpg?alt=media&token=a5121fd9-680b-4a48-b9c9-c9de84d1f76f",
    category: "brunch",
  },
  {
    id: 33,
    name: "Yogurt Parfait(Granola fresh yougurt,fresh fruits topped with \nchocolate /strawberry sauce).",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg.png?alt=media&token=576678df-cda3-458f-b928-b0c64d22f5f2",
    category: "brunch",
  },
  {
    id: 34,
    name: "Expresso",
    price: 30,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/d97dfdd3-e074-45f7-a3fd-dbe7e2c62650.JPG?alt=media&token=2cf5fdc6-fc4f-47e6-b071-5a300d4512b0",
    category: "coffee / tea",
  },
  {
    id: 35,
    name: "Americano",
    price: 30,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg%20(7).png?alt=media&token=aec1ec06-664d-46aa-a8f5-3e6a37f14983",
    category: "coffee / tea",
  },
  {
    id: 36,
    name: "Cappuccino",
    price: 30,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg%20(8).png?alt=media&token=8c8e04a1-3cd1-4b65-9b0d-3bbb97450603",
    category: "coffee / tea",
  },
  {
    id: 37,
    name: "Latte(iced option available)",
    price: 30,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_x0icikx0icikx0ic.jpeg?alt=media&token=ab8b4c1e-dd86-4e8c-b055-24cb568e56d1",
    category: "coffee / tea",
  },
  {
    id: 38,
    name: "Hot Chocolate",
    price: 20,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/54387b7e-beeb-417b-b5ed-6fbd16d1fca1.JPG?alt=media&token=e75a927f-e05c-47bb-acd0-e090018e3073",
    category: "coffee / tea",
  },
  {
    id: 39,
    name: "Sparkling lemon iced tea",
    price: 30,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/pngegg%20(9).png?alt=media&token=8e97d10f-d832-4858-8899-81313682d197",
    category: "coffee / tea",
  },
  {
    id: 40,
    name: "Sparkling peach Ice tea",
    price: 30,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_jskyemjskyemjsky.jpeg?alt=media&token=863dba46-f2e4-4d4f-81c3-9ca7d0345727",
    category: "coffee / tea",
  },
  {
    id: 41,
    name: "Vanilla milkshake",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/0b556bb8-4e99-4bb9-87ff-ce21750c133d.JPG?alt=media&token=75798655-c61f-4abb-85bf-4d0e3743a2b7",
    category: "premium shakes",
  },
  {
    id: 42,
    name: "Chocolate milkshake",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/4eb5d9a5-e9d6-4b33-b25a-743815b2eae9.JPG?alt=media&token=9323ee32-b3b5-4c66-9b83-7a6263384e5e",
    category: "premium shakes",
  },
  {
    id: 43,
    name: "Strawberry milkshake",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/1f015eee-6b0c-4bf9-8239-01df47fae687.JPG?alt=media&token=fb686445-9aa3-4f41-97a3-069cec547c83",
    category: "premium shakes",
  },
  {
    id: 44,
    name: "Oreo Milkshake",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/c432126a-a3e4-4317-9133-5844a72e1c95.JPG?alt=media&token=f9899c69-8f7c-4a1b-ae4c-d1560a8aec90",
    category: "premium shakes",
  },
  {
    id: 45,
    name: "Boozy Milkshake",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_x0icikx0icikx0ic.jpeg?alt=media&token=ab8b4c1e-dd86-4e8c-b055-24cb568e56d1",
    category: "premium shakes",
  },
  {
    id: 46,
    name: "Vegan Smoothie(Oats milk,peanut butter,cocoa powder,cinnamon,\ncaramel sauce)",
    price: 40,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/4647c7fd-b655-409f-b6d6-4d2956e5e1d5.JPG?alt=media&token=7c8e22e0-58b8-486d-90bc-7acabf8b0821",
    category: "smoothies",
  },
  {
    id: 47,
    name: "All Green Smoothie(Kale,Cashew,ginger,lemon juice,apple,cucumber,\nmoringa leaves,celery)",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/4647c7fd-b655-409f-b6d6-4d2956e5e1d5.JPG?alt=media&token=7c8e22e0-58b8-486d-90bc-7acabf8b0821",
    category: "smoothies",
  },
  {
    id: 48,
    name: "Tropical blend(Tropical fruits blend with honey n cream milk.)",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.32_27f5e064.jpg?alt=media&token=50746aa2-2de5-4bad-b79e-6d5e764696d4",
    category: "smoothies",
  },
  {
    id: 49,
    name: "Knk Signature smoothie(Fruits and nuts blend)",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/IMG-20240503-WA0039.jpg?alt=media&token=7407c734-00c9-468a-bc20-441ef9badbd3",
    category: "smoothies",
  },
  {
    id: 50,
    name: "Fruit splash(Melon,coconut water,almond milk,honey,celery)",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/74b2c0e0-b25b-40ef-a45a-ced41fb2eff5.JPG?alt=media&token=ab25a230-3d10-491e-8ad2-35d54b36687d",
    category: "smoothies",
  },
  {
    id: 51,
    name: "Banana Express(Banana,Mango,milk)",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/cd60e4c6-6fd4-4809-8854-ba19fe25f7ce.JPG?alt=media&token=15c72fcc-f7b3-42dc-82d5-420662dce1a1",
    category: "smoothies",
  },
  {
    id: 52,
    name: "Coconut Smoothies",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.32_32e736e1.jpg?alt=media&token=44e101f4-41c5-4618-8e11-6abc3b3e381d",
    category: "smoothies",
  },
  {
    id: 53,
    name: "Pina Colada Smoothie",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.30_c2875991.jpg?alt=media&token=cc5f8cab-c2f3-426a-8c1d-7b8f0a7b8db2",
    category: "smoothies",
  },
  {
    id: 54,
    name: "Berries Smoothie",
    price: 45,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/WhatsApp%20Image%202024-05-06%20at%2007.03.30_4d78ac9e.jpg?alt=media&token=cfffdb4d-d627-422b-a8ef-cfef8e9eaa82",
    category: "smoothies",
  },
  {
    id: 55,
    name: "Greek Salad",
    price: 60,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/R.jpeg?alt=media&token=37614e0b-c2a6-4792-af3b-8289c8c92768",
    category: "salads",
  },

  {
    id: 30,
    name: "BIG K All Day Breakfast (baked beans,baked potatoes,sausage,mushrooms\n,bacon,grilled tomatoes,jam/butter/eggs,pancake n maple syrup, avocado ,\n toast SIDE* Hot chocolate/Fresh juice or coffee",
    price: 200,
    img: "https://firebasestorage.googleapis.com/v0/b/cafeknk-4aca7.appspot.com/o/Gemini_Generated_Image_x0icikx0icikx0ic.jpeg?alt=media&token=ab8b4c1e-dd86-4e8c-b055-24cb568e56d1",
    category: "brunch",
  },
];
