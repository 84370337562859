import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyACECbKLSopWe2HujvZtzoQ7bpvbQdaFGc",
  authDomain: "cafeknk-4aca7.firebaseapp.com",
  projectId: "cafeknk-4aca7",
  storageBucket: "cafeknk-4aca7.appspot.com",
  messagingSenderId: "202008930656",
  appId: "1:202008930656:web:35b9e4c24e8e5b343bd605",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
